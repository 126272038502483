import React, { useEffect } from 'react';

import hyappLogo from '../resource/img/logo-hyapp.png';

import receptionLogo from '../resource/img/welcome/reception.svg';
import huileLogo from '../resource/img/welcome/huile.svg';
import temperatureLogo from '../resource/img/welcome/temperature.svg';
import tracabiliteLogo from '../resource/img/welcome/trace.svg';
import refroidissementLogo from '../resource/img/welcome/refroidissement.svg';
import remiseTemperatureLogo from '../resource/img/welcome/remise.svg';
import nettoyageLogo from '../resource/img/welcome/nettoyage.svg';
import mixeLogo from '../resource/img/welcome/mixe.svg';
import serviceLogo from '../resource/img/welcome/service.svg';
import sondeLogo from '../resource/img/welcome/sonde.svg';
import etiquetteLogo from '../resource/img/welcome/etiquette.svg';

function WelcomeComponent(props) {

  return (
    <div className="welcomeComponent">
      <p>{props.name}</p>
        <img src={props.logo} className="vmiddle" alt="" />
    </div>
  )
}


function Welcome() {
  useEffect(() => {

  }, []);

  return (
    <div id="welcomePage" className="hero-body is-light">
      <div className="container">
        <div className="welcome">
          <div className="welcomeTop">
            <div className="welcomeTitle">Votre pack hygiène connecté !</div>
            <div className="welcomeSubtitle">A partir de 29€/mois</div>
          </div>
          <div className="welcomeUp">
            <img src={hyappLogo} alt="Logo HyAPP" />
            <div className="appElements">
              <div># Pour toutes structures du secteur alimentaire</div>
              <div># Gain de temps au quotidien</div>
              <div># Zéro papier pour vos autocontrôles</div>
              <div># Données stockées et sécurisées</div>
              <div># Simplifie le PMS et la démarche HACCP</div>
              <div># Gestion et organisation à distance</div>
              <div># 100% compatible Android et ios (smartphone et tablette)</div>
              <div># Développé par le laboratoire NORMEC ABIOLAB LABHYA, expert en hygiène alimentaire</div>
            </div>
          </div>
          <div className="welcomeMiddle">
            <div className="welcomeText">Vous souhaitez obtenir notre version DEMO gratuite ou être mis en relation avec un interlocuteur : <a href="mailto:assistance@hyapp.fr">assistance@hyapp.fr</a> ou par téléphone au 06&nbsp;70&nbsp;33&nbsp;88&nbsp;86.</div>
          </div>
          <div className="welcomeBottom">
            <WelcomeComponent name={"Réception"} logo={receptionLogo} />
            <WelcomeComponent name={"T°c stockage"} logo={temperatureLogo} />
            <WelcomeComponent name={"Refroidissement"} logo={refroidissementLogo} />
            <WelcomeComponent name={"Remise T°c"} logo={remiseTemperatureLogo} />
            <WelcomeComponent name={"Mixés"} logo={mixeLogo} />
            <WelcomeComponent name={"Huile"} logo={huileLogo} />
            <WelcomeComponent name={"T°c service/livraison"} logo={serviceLogo} />
            <WelcomeComponent name={"Nettoyage"} logo={nettoyageLogo} />
            <WelcomeComponent name={"Tracabilité"} logo={tracabiliteLogo} />
            <WelcomeComponent name={"Etiquettes"} logo={etiquetteLogo} />
            <WelcomeComponent name={"Sondes connectées"} logo={sondeLogo} />
          </div>
        </div>
      </div>

    </div>
  );
}

export default Welcome;