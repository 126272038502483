import React, { useState, useEffect } from 'react';

import { callApiGet, callApiPut, callApiPost } from '../Helper/Network';

import { HyappEdit, SurfaceEdit, ActionEdit } from '../Helper/Form'

import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Editor(props) {
  const location = useLocation().pathname.replace("/edit", "").replace("/view", "");

  const [firstLoad, setFirstLoad] = useState(true);
  const [data, setData] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);
  const [references, setReferences] = useState(undefined);
  const [dataId, setDataId] = useState(undefined);

  //specfic for surfaces
  const [surfaceToDelete, setSurfaceToDelete] = useState([]);
  const [surfaces, setSurfaces] = useState([]);

  //specific for non conformites
  const [actions, setActions] = useState([]);

  const [tempHeader, setTempHeader] = useState(0);
  
  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  //specific for date

  const loadData = async () => {
    try {
      var url = location;
      
      //load data element
      const res = await callApiGet(url + "/edit", navigate);

      //check for headers
      res.headers  ? setHeaders(res.headers) : setHeaders(undefined);
      res.id ? setDataId(res.id): setDataId(undefined);

      if (res.data) {
        setData(res.data)
      }
      else {
        //in create mode, create empty references
        var emptyData = [];
        res.headers.map((header) => {
          if (header.type === "boolean") {
            // Lecture seule cochée par défaut pour les établissements
            if (header.value==="et_acces_donne") {
              return emptyData[header.value] = true;
            }
            return emptyData[header.value] = false;
          }
          // Tous les jours sont sélectionnés par défaut si on définit un établissement
          if (header.value==="et_jour_ouverture") {
            return emptyData[header.value] = [1,2,3,4,5,6,7];
          }
          // Tous les modules sont sélectionnés par défaut si on définit un établissement
          if (header.value==="et_module") {
            return emptyData[header.value] = [1,2,3,4,5,6,7,8,9];
          }
          // Tous les types de produits livrés par défaut si on définit un fournisseur
          if (header.value==="fou_type_produit") {
            return emptyData[header.value] = [1,2,3];
          }
          console.log(header.value);
          if (header.value==="type_abo") {
            return emptyData[header.value] = 1;
          }
          return emptyData[header.value] = null;
        });
        emptyData[dataId] = -1;
        setData(emptyData);
      }

      // if element use external references
      if (res.references) {
        var loadedReferences = await Promise.all(res.references.map(async (reference) => {
          //load references
          var res2 = await callApiGet(url + "/select/" + reference, navigate);
          var result = [];
          result[reference] = res2;
          return { ref: reference, data: res2 };
        }));

        var finalReferences = [];
        loadedReferences.map((refe) => {
          finalReferences[refe.ref] = refe.data;
          return undefined;
        })
        setReferences(finalReferences);
      }

      if (props.mode && props.mode === "zone" && res.data.surfaces) {
        setSurfaces(res.data.surfaces);
      }
      if (props.mode && props.mode === "non_conformite" && res.data.actions) {
        setActions(res.data.actions);
      }

    }
    catch (error) {

    }
  }

  const updateData = (element_index, value) => {
    var cloned = [];
    headers.forEach((h) => {
      cloned[h.value] = data[h.value];
    })
    cloned[dataId] = data[dataId];
    cloned[element_index] = value;

    if (element_index === 'type_enceinte_tyen_id') {
      if (value === 1) { //froid négatif
        cloned['enc_min_temp']=null;
        cloned['enc_max_temp']=-18;
      }
      if (value === 2) { //froid positif
        cloned['enc_min_temp']=0;
        cloned['enc_max_temp']=4;
      }
      if (value === 3) { //Maintien au chaud
        cloned['enc_min_temp']=63;
        cloned['enc_max_temp']=null;
      }
      setTempHeader(tempHeader + 1);
    }
    
    setData(cloned);
    
  }

  const save = async () => {
    var requestBody = Object.assign({}, data);
    if (props.mode && props.mode === "zone") {
      var newSurfaces = [];
      var updateSurfaces = [];

      surfaces.forEach((surface) => {
        if (surface.sur_id === -1) {
          newSurfaces.push(surface);
        }
        else {
          updateSurfaces.push(surface);
        }
      })

      requestBody["surfaces"] = newSurfaces;
      requestBody["surfaceToDelete"] = surfaceToDelete;
      requestBody["surfaceToUpdate"] = updateSurfaces;
    }
    if (props.mode && props.mode === "non_conformite") {
      var newActions = [];
      actions.forEach((action) => {
        var act = {
          act_description: action.act_description
        }
        newActions.push(act);

      })
      requestBody["actions"] = newActions;
    }

    try {
      if (!data[dataId] || data[dataId] === -1) { 
        //create element
        await callApiPut(location, requestBody, navigate);
      }
      else { //update element
        await callApiPost(location, requestBody, navigate);
      }
      navigate(-1);
    }
    catch (error) {

    }    
  }

  useEffect(() => {
    if (firstLoad) {
      loadData();
      setFirstLoad(false);
    }
  }, [data, headers, references])



  // SURFACE Spécifique
  const addSurface = () => {
    var newSurfaces = [...surfaces, { sur_id: -1, sur_nom: "", frequence_surface_frsu_id: null, sur_mois: null} ];
    setSurfaces([...newSurfaces]);
  }
  const modifySurface = (index, surface) => {
    var newSurfaces = [...surfaces];
    newSurfaces[index]=surface;
    setSurfaces([...newSurfaces]);
  }
  const deleteSurface = (index) => {
    var newSurfaces = []
    var newDelete = [...surfaceToDelete]
    surfaces.forEach((surface, id) => {
      if (index !== id) {
        newSurfaces.push({...surface})
      }
      else {
        if (surface.sur_id !== -1) {
          newDelete.push(surface);
        }
      }
    })
    setSurfaces([...newSurfaces]);
    setSurfaceToDelete([...newDelete]);
  }

  //non conformité spécifique
  const addAction = () => {
    var newActions = [...actions, { act_id: -1, act_description: ""} ];
    setActions([...newActions]);
  }
  const modifyAction = (index, action) => {
    var newActions = [...actions];
    newActions[index]=action;
    setActions([...newActions]);
  }
  const deleteAction = (index) => {
    var newActions = [...actions]
    newActions.splice(index, 1)
    setActions([...newActions]);
  }

  var zoneElements = []
  if (props.mode && props.mode === "zone") {
    if (surfaces) {
      zoneElements = surfaces.map((surface, index) => {
        return (
          <SurfaceEdit
            key={surface.sur_id + " " + index}
            index={index}
            surface={surface}
            callback={modifySurface}
            deleteCallback={deleteSurface}
            />
        )})
    }
  }
  var actionElements = []
  if (props.mode && props.mode === "non_conformite") {
    if (actions) {
      actionElements = actions.map((action, index) => {
        return (
          <ActionEdit
            key={action.act_id + " " + index}
            index={index}
            action={action}
            callback={modifyAction}
            deleteCallback={deleteAction}
            />
        )
      })
    }
  }

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1 className="hyappTitle">{props.title}</h1>
        </div>
      </div>
      <div className="columns is-multiline is-vcentered">
      {
        headers ?
          headers.map((header, index) => {
            var ref = undefined;
            if (header.reference && references && header.reference in references) {
              ref = references[header.reference];
            }

            if (header.value === 'enc_min_temp' || header.value === 'enc_max_temp') {
              return (<HyappEdit 
                key={index + " " + tempHeader} 
                label={header.label} 
                type={header.type} 
                value={data[header.value]} 
                reference={ref}
                element={header.value}
                callback={updateData} />)
            }

            return (<HyappEdit 
                key={index} 
                label={header.label} 
                type={header.type} 
                value={data[header.value]} 
                reference={ref}
                element={header.value}
                callback={updateData} />)

          })
          : <></>
      }
      {
        props.mode && props.mode === "zone" ?
          (
            <>
            <div className="column is-one-fifth label-right">
              <label className="label">Surface et matériels associés</label>
            </div>
            <div className="column is-four-fifths display-value">
              <div className="columns is-multiline is-vcentered">
              {
                zoneElements
              }
              <Link className="button is-warning" onClick={addSurface}><i className="fa fa-plus" /></Link>
              </div>
            </div>
            </>
          )
        :
        <></>
      }
      {
        props.mode && props.mode === "non_conformite" ?
          (
            <>
            <div className="column is-one-fifth label-right">
              <label className="label">Actions</label>
            </div>
            <div className="column is-four-fifths display-value">
              <div className="columns is-multiline is-vcentered">
              {
                actionElements
              }
              <Link className="button is-warning" onClick={addAction}><i className="fa fa-plus" />Nouvelle action</Link>
              </div>
            </div>
            </>
          )
        :
        <></>
      }      
      </div>
      {
        <>
        <Link className="button is-warning" onClick={save}><i className="fa fa-save" />Enregistrer</Link>
        <Link className="button is-dark" onClick={goBack}>Annuler</Link>
        </>
      }
    </>
  );
}

export default Editor;

//chargement d'un element
export const editorParseUrl = async ({ params }) => {
  const { element_id } = params;
  return element_id;
}



