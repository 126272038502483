import React, { useContext, useEffect } from 'react';


import TopBar from './CommonPageElements/TopBar';
import Menu from './CommonPageElements/Menu';
import Footer from './CommonPageElements/Footer';


import { MyContext } from './context/MyContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'

import { Outlet, useLocation } from 'react-router-dom';

//small hack to process HashRouter when arriving on /login from hyapp.fr
function redirectToLogin() {
  if (window.location.href === "https://pro.hyapp.fr/login") {
    window.location.href = "https://pro.hyapp.fr/#/login";
  }
}

function App() {

  const { loggedUser } = useContext(MyContext);

  useEffect(() => {
    document.body.classList.add('has-navbar-fixed-top');
  }, []);

  const location = useLocation();
  var color = "#f5f5f5";
  if (location.pathname === '/' || location.pathname === '/login') {
    color = "#ffffff";
  }

  // add is-gapless to columns to remove gap on bottom, but issue with other components
  return (
    <>
    {
      redirectToLogin()
    }
      <TopBar />
      <div className="columns is-fullheight-with-navbar">
        {
          loggedUser
            ? (<Menu />)
            : (<></>)
        }
      
        <div className="column is-main-content" style={{backgroundColor: color}}>
          <Outlet />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}



export default App;