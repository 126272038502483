import React, { useState, useContext } from 'react';
import { callApiPost } from '../Helper/Network';

import { toast } from 'react-toastify';

import { useLocation, useNavigate } from 'react-router-dom';

function PasswordReset(props) {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const tokenParam = queryParameters.get("token");
  const emailParam = queryParameters.get("email");
  const modeParam = queryParameters.get("mode");

  const [token, setToken] = useState(tokenParam);
  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [consent, setConsent] = useState(false);


  const navigate = useNavigate();

  const save = async () => {
    if (password !== confirmPassword) {
      toast.error("Les mots de passes saisis ne correspondent pas");
      return;
    }
    if (!consent) {
      toast.error("Vous devez donner votre consentement");
      return;
    }
    var passwordReset = {
      token: token,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    }

    try {
      await callApiPost("/reset", passwordReset);
      navigate("/login?mode=" + modeParam);
    }
    catch (error) {
    }
  }

  const handleValidate = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      save();
    }
  };

  return (
    <>
      <div className="container is-centered">
        <div className="columns is-centered">
          <div className="column is-12-mobile is-8-tablet is-8-desktop">

            <h1 className="is-size-2">Réinitialisation de mot de passe</h1>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">E-mail</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input className="input" placeholder="e-mail" value={email} onChange={e => setEmail(e.target.value)} readOnly={true} onKeyDown={handleValidate}  />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Mot de passe</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input className="input" type="password" placeholder="mot de passe" value={password} onChange={e => setPassword(e.target.value)} readOnly={false} onKeyDown={handleValidate} />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Confirmez votre mot de passe</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                    <input className="input" type="password" placeholder="mot de passe" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} readOnly={false} onKeyDown={handleValidate} />
                  </p>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Consentement</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control">
                  <input type="checkbox" onChange={e => setConsent(e.target.checked)}/> En cochant cette case, vous autorisez HyAPP à enregistrer vos données utilisateurs
                  </p>
                </div>
              </div>
            </div>            


            <button className="button is-warning" onClick={save}>Sauvegarder les modifications</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordReset;
