import React, {useContext} from 'react';

import { Link } from 'react-router-dom';
import { MyContext } from '../context/MyContext';
import ArrayComponent from '../Components/ArrayComponent';

function Lister(props) {
  const { loggedUser } = useContext(MyContext);

  var create = null;
  if (props.createText) {
    // quick fix
    if (props.createText === "Créer un établissement" && loggedUser.role_role_id === 20) {
      create = null;
    }
    else if (props.createText === "Créer un abonnement") {
      create = <><Link className="button is-success" to={"-1/edit"}>{props.createText}</Link><Link className="button is-success" to={"/demo/-1/edit"}>Créer un abonnement de démo</Link></>
    }
    else {
      create = <Link className="button is-success" to={"-1/edit"}>{props.createText}</Link>
    }
  }

  return (
    <div>
        <ArrayComponent title={props.title} elementType={props.elementType} multiDelete={props.multiDelete}/>
        {
          create
        }       
    </div>
  );
}

export default Lister;
